.Show {

    * {
        color: white;
    }
    .inner {
        padding-top: 32px;
        display: flex;
    }
    .left {
        width: 20%;
        display: block;
        font-weight: bold;
        
        .left-bottom {
            padding-top: 6px;
        }
    }
    .center {
        width: 60%;
        display: block;
        
        .center-bottom {
            padding-top: 6px;

            span.ireland {
                color: green;
            }
        }
    }
    
    .right {
        width: 20%;
        
        a {
            border: 1px solid white;
            padding: 6px;
            font-weight: bold;
            font-style: none;
            text-decoration: none;
        }
    }

    @media (max-width: 600px) {

        border-bottom: 1px solid white;
        padding-bottom: 28px;

        .inner {
            display: block;

            > div {
                width: 100%;
            }

            .center {
                margin-top: 12px;
            }

            .right {
                margin-top: 28px;
            }
        }
    }
}