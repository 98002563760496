#podcast {
  background-color: black;
  width: 100%;
  min-height: 100vh;
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("../../static/9848DD17-ECBA-449E-BC06-6D4174AB68FD.JPG");
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;

  .container {
    padding-top: 48px;
    text-align: center;
    background: rgba(0, 0, 0, 0.7);
    
    .subtext {
      max-width: 600px;
      color: white;
      margin: 0 auto;
      text-align: center;
      color: white;
      -webkit-background-clip: text;
      background-clip: text;
      font-family: Oswald, sans-serif;
      font-size: 18px;
    }
  }

  h1 {
    position: relative;
    display: block;
    font-weight: bold;
    text-decoration: none;
    color: white;
    -webkit-background-clip: text;
    background-clip: text;
    font-family: Oswald, sans-serif;
  }
  .logo-container {
    margin: 0 auto;
    margin-top: 28px;
  }
  .logos {
    img {
      width: 48px;
      height: 48px;
      padding: 4px;
    }
  }
}
